import React from 'react';

import { graphql } from 'gatsby';

import { Blog } from 'components/blog';
import Hero from 'components/hero';

const sortPosts = (posts) => {
  return posts.edges.sort(function (e, e2) {
    const convertDate = (date) => {
      if (!date) {
        return new Date(null);
      }
      const dateParts = date.split('/');

      return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    };

    return convertDate(e2.node.post.date) - convertDate(e.node.post.date);
  });
};

const TemplateBlog = (props) => {
  //PROPS
  const {
    data: {
      page: {
        hero: { title, text, subtitle },
      },
      posts,
    },
    location,
  } = props;
  //PROPS

  return (
    <main className="main">
      <Hero data={{ title, subtitle, text }} center />
      <Blog data={sortPosts(posts)} location={location} />
    </main>
  );
};

export const query = graphql`
  query Blog($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      hero {
        title
        text
        subtitle
      }
    }
    posts: allWpPost {
      edges {
        node {
          id
          title
          slug
          date
          categories {
            nodes {
              name
              slug
            }
          }
          post {
            author {
              ... on WpColaborador {
                title
                slug
              }
            }
            date
            thumbText
            thumbTitle
            thumbImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, aspectRatio: 1.25)
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default TemplateBlog;
